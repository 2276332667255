<template>
  <form v-if="isVisible">
    <vue-form-generator
      :schema="schema"
      :model="model"
      :options="formOptions"
      @model-updated="modelUpdated"
      @field-updated="submitField"
    />
  </form>
</template>

<script>
import _ from 'lodash'
import api from '../../../../../api'
import { Engine } from 'json-rules-engine'
import fieldAutocomplete from '../../../share/form/type/fieldAutocomplete'
import FieldRequirementValidator from '../../../share/mixins/FieldRequirementValidator'
import Loader from '../../../../share/Loader'
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import fieldMultiselect from '../../../share/form/type/fieldMultiselect'
import postCodeMultiselect from '../../../share/form/type/postCodeMultiselect'
import fieldMoney from '../../../share/form/type/fieldMoney'
Vue.component('fieldMultiselect', fieldMultiselect)
Vue.component('field-postCodeMultiselect', postCodeMultiselect)
Vue.component('fieldAutocomplete', fieldAutocomplete)
Vue.component('fieldMoney', fieldMoney)

Vue.use(VueFormGenerator)

export default {
  components: {
    VueFormGenerator: VueFormGenerator.component
  },
  mixins: [
    Loader,
    FieldRequirementValidator
  ],
  props: {
    task: { type: Object, required: true, default () { return {} } },
    taskData: { type: Object, required: true, default () { return {} } }
  },
  data () {
    return {
      rulesEngine: this.createRulesEngine(),
      service: this.$route.meta.acl.service,
      isVisible: false,
      schemaName: 'appraisal',
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        fieldIdPrefix: 'task-'
      },
      model: {},
      modelChanged: {},
      schema: {
        groups: []
      },
      fieldSendDataMap: {
        voivodeship: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        county: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        borough: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        city: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        postCode: {
          method: this.getModelDataByPath,
          args: ['postcode']
        }
      }
    }
  },
  mounted () {
    this.loadSchema()
  },
  updated () {
    this.validateRequirement()
  },
  methods: {
    getModelDataByPath (model, prop) {
      if (typeof model === 'string') {
        return model
      }

      return _.has(model, prop) ? _.get(model, prop) : null
    },
    createRulesEngine () { return new Engine() },
    loadData () {
      this.model = Object.assign({}, this.model, this.taskData)
      if (this.task.state) {
        this.$events.$emit('dashboard:submenu:appraisalTaskInInitialState', this.task.state.initial)
        if (!this.task.state.initial && !this.task.state.final) {
          this.$events.$emit('dashboard:submenu:showAll')
        }
      }
    },
    getLoadSchemaMethod () {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/schemas/${this.schemaName}`, { state: this.task.state.id, client: this.$route.meta.client })
        : api.request(this.service, 'get', `/schemas/${this.schemaName}`, { state: this.task.state.id })
    },
    loadSchema () {
      this.getLoadSchemaMethod()
        .then((result) => {
          this.buildSchema(result.data)
          this.loadData()
          this.isVisible = true
          this.$notify({
            type: 'success',
            text: 'Schemat formularza załadowany poprawnie'
          })
        }).then(() => {
          this.$events.$emit('dashboard:submenu:taskLoaded')
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            text: 'Błąd ładowania schematu formularza'
          })
        })
    },
    buildSchema (schema) {
      for (let g = 0; g < schema.groups.length; g++) {
        const group = schema.groups[g]
        let fields = []
        let groupFields = Object.values(group.fields)
        for (let f = 0; f < groupFields.length; f++) {
          fields.push(this.createField(groupFields[f]))
        }
        if (group.fields.length > 0) {
          this.schema.groups.push(this.createGroup(group, fields))
        }
      }
    },
    createGroup (group, fields) {
      let result = {
        fields
      }
      if (group.label) {
        result.legend = group.label
      }
      return result
    },
    createField (fieldItem) {
      let field = fieldItem.field
      let fieldParams = {
        type: field.type,
        label: field.label,
        model: field.model,
        disabled: this.isFieldDisabled(fieldItem),
        required: fieldItem.required ? fieldItem.required : false
      }

      if (field.settings !== null) {
        Object.assign(fieldParams, this.setSettings(field))
      }

      return fieldParams
    },
    setSettings (field) {
      let settings = field.settings

      if (settings.value) {
        this.model[field.model] = settings.value
      }

      let fieldParams = {}
      fieldParams.readonly = settings.readonly ? settings.readonly : false
      fieldParams.relatedFields = settings.relatedFields ? settings.relatedFields : []
      Object.assign(fieldParams, this.setSpecificSettings(field))

      return fieldParams
    },
    setSpecificSettings (field) {
      let fieldParams = {}

      switch (field.type) {
        case 'input':
          fieldParams.inputType = field.inputType
          fieldParams.maxlength = field.settings.maxlength
          break

        case 'select':
          fieldParams.values = field.settings.values
          fieldParams.selectOptions = { hideNoneSelectedText: true }
          break

        case 'radios':
          fieldParams.values = field.settings.values
          break

        case 'autocomplete':
          fieldParams.source = '/'
          break
        case 'multiselect':
        case 'postCodeMultiselect':
        case 'personMultiselect':
          fieldParams = this.prepareMultiselectParams(field)
          break

        case 'dateTimePicker':
          fieldParams.dateTimePickerOptions = field.settings.dateTimePickerOptions
      }

      return fieldParams
    },
    isFieldDisabled (fieldItem) {
      if (_.has(fieldItem, 'field.settings.readonly')) {
        return fieldItem.field.settings.readonly
      }
      return fieldItem.readonly ? fieldItem.readonly : false
    },
    formatSourceModelName (source) {
      return source.split('').filter(char => char !== '[' && char !== ']' && char !== '.').join('')
    },
    prepareMultiselectParams (field) {
      let fieldParams = {}
      let fieldModel = this.model[field.model]

      if (field.settings.params) {
        fieldParams.params = field.settings.params
      }

      if (field.settings.source) {
        fieldParams.source = field.settings.source
      }

      if (field.settings.options) {
        fieldParams.options = field.settings.options
      }

      if (_.isArray(fieldModel) && !_.isEmpty(fieldModel)) {
        let collectionField = fieldModel[field.collectionIndex]

        let options = {
          id: collectionField.id,
          label: collectionField.forename + ' ' + collectionField.surname,
          entity: collectionField
        }

        fieldParams.options = [options]
        fieldParams.value = options
      }

      return fieldParams
    },
    modelUpdated (e, source) {
      let model = this.modelChanged
      model[this.formatSourceModelName(source)] = true
      this.modelChanged = model
      this.validateRequirement()
    },
    submitField (newVal, schema, target) {
      if (!this.modelChanged[this.formatSourceModelName(schema.model)]) {
        return
      }

      if(schema.type === 'postCodeMultiselect' && newVal !== null && newVal.hasOwnProperty('city')) {
        newVal.borough = newVal.municipality
        Object.entries(newVal).filter(([key,value], index) => schema.relatedFields.includes(key)).forEach(([key,value], index) => {
          this.model[key] = value
        })
      }
      target.classList.add('saving-field')

      // wait for all watchers to finish
      this.$nextTick(() => {
        api.request(this.service, 'put', this.$isWithClients(this.$route.meta.acl.service) ? `/tasks/${this.$route.params.appraisalTaskId}` : `/tasks/${this.$route.params.id}/appraisal/${this.$route.params.appraisalTaskId}`, this.createFieldDataToSend(newVal, schema))
          .then(() => {
            this.modelChanged[this.formatSourceModelName(schema.model)] = false
            target.classList.remove('saving-field')
            target.classList.add('field-saved')
            setTimeout(() => target.classList.remove('field-saved'), 1500)
          })
          .catch(() => {
            this.modelChanged[this.formatSourceModelName(schema.model)] = false
            target.classList.remove('saving-field')
            target.classList.add('field-error')
            setTimeout(() => target.classList.remove('field-error'), 3600)
            this.$notify({
              type: 'error',
              text: 'Błąd zapisu danych'
            })
          })
      })
    },
    createFieldDataToSend (newVal, schema) {
      let dataToSend = {}
      dataToSend[schema.model] = newVal

      if (this.fieldSendDataMap[schema.model] !== undefined) {
        newVal = this.fieldSendDataMap[schema.model].method(newVal, ...this.fieldSendDataMap[schema.model].args)
      }
      dataToSend[schema.model] = newVal

      if (schema.hasOwnProperty('relatedFields')) {
        schema.relatedFields.forEach((relatedField) => {
          if (this.fieldSendDataMap[relatedField] !== undefined) {
            dataToSend[relatedField] = this.fieldSendDataMap[relatedField].method(this.model[relatedField], ...this.fieldSendDataMap[relatedField].args)
          }
        })
      }

      if (this.$isWithClients(this.service)) {
        dataToSend['appraisal'] = true
        dataToSend['client'] = this.$route.meta.client
      }
      return dataToSend
    }
  }
}
</script>
