<template>
  <form v-if="isVisible">
    <vue-form-generator
      :schema="schema"
      :model="model"
      :options="formOptions"
      @model-updated="modelUpdated"
      @field-updated="submitField"
      @collection-updated="submitCollection"
    />
  </form>
</template>

<script>
import _ from 'lodash'
import api from '../../../../../api'
import { Engine } from 'json-rules-engine'
import fieldAutocomplete from '../../../share/form/type/fieldAutocomplete'
import FieldRequirementValidator from '../../../share/mixins/FieldRequirementValidator'
import Loader from '../../../../share/Loader'
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import fieldMultiselect from '../../../share/form/type/fieldMultiselect'
import fieldPersonMultiselect from '../../../share/form/type/fieldPersonMultiselect.js'
import fieldCollection from '../../../share/form/type/fieldCollection'
import postCodeMultiselect from '../../../share/form/type/postCodeMultiselect'
import fieldMoney from '../../../share/form/type/fieldMoney'
Vue.component('fieldMultiselect', fieldMultiselect)
Vue.component('fieldPersonMultiselect', fieldPersonMultiselect)
Vue.component('fieldCollection', fieldCollection)
Vue.component('field-postCodeMultiselect', postCodeMultiselect)
Vue.component('fieldAutocomplete', fieldAutocomplete)
Vue.component('fieldMoney', fieldMoney)

Vue.use(VueFormGenerator)

export default {
  components: {
    VueFormGenerator: VueFormGenerator.component
  },
  mixins: [
    Loader,
    FieldRequirementValidator
  ],
  props: {
    task: { type: Object, required: true, default () { return {} } },
    taskData: { type: Object, required: true, default () { return {} } }
  },
  data () {
    return {
      rulesEngine: this.createRulesEngine(),
      service: this.$route.meta.acl.service,
      isVisible: false,
      schemaName: 'appraisal',
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        fieldIdPrefix: 'task-'
      },
      model: {},
      modelChanged: {},
      schema: {
        groups: []
      },
      schemaCollection: {},
      fieldSendDataMap: {
        voivodeship: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        county: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        borough: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        city: {
          method: this.getModelDataByPath,
          args: ['label']
        },
        postCode: {
          method: this.getModelDataByPath,
          args: ['postcode']
        },
        contactPersons: {
          method: this.prepareContactPersonData
        },
        owners: {
          method: this.prepareOwnerData
        }
      }
    }
  },
  mounted () {
    this.loadSchema()
  },
  updated () {
    this.validateRequirement()
  },
  methods: {
    getModelDataByPath (model, prop) {
      if (typeof model === 'string') {
        return model
      }

      return _.has(model, prop) ? _.get(model, prop) : null
    },
    createRulesEngine () { return new Engine() },
    loadData () {
      this.model = Object.assign({}, this.model, this.taskData)
      if (this.task.state) {
        this.$events.$emit('dashboard:submenu:appraisalTaskInInitialState', this.task.state.initial)
        if (!this.task.state.initial && !this.task.state.final) {
          this.$events.$emit('dashboard:submenu:showAll')
        }
      }
    },
    getLoadSchemaMethod () {
      return this.$isWithClients(this.service)
        ? api.request(this.service, 'get', `/schemas/${this.schemaName}`, { state: this.task.state.id, client: this.$route.meta.client })
        : api.request(this.service, 'get', `/schemas/${this.schemaName}`, { state: this.task.state.id })
    },
    loadSchema () {
      this.getLoadSchemaMethod()
        .then((result) => {
          this.loadData()
          this.buildSchema(result.data)
          this.$store.commit('SET_CURRENT_TASK_SCHEMA', result.data)

          this.isVisible = true
          this.$notify({
            type: 'success',
            text: this.$t('pages.taskDetails.form.successSchemaLoadNotification')
          })
        }).then(() => {
          this.$events.$emit('dashboard:submenu:taskLoaded')
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            text: this.$t('pages.taskDetails.form.errorSchemaLoadNotification')
          })
        })
    },
    buildSchema (schema) {
      for (let g = 0; g < schema.groups.length; g++) {
        const group = schema.groups[g]
        let fields = []
        let groupFields = Object.values(group.fields)
        for (let f = 0; f < groupFields.length; f++) {
          let baseField = groupFields[f]

          if (baseField.field.settings && baseField.field.settings.collection) {
            let collectionName = baseField.field.settings.collection

            if (this.schemaCollection[collectionName] === undefined) {
              this.schemaCollection[collectionName] = []
            }

            this.schemaCollection[collectionName].push(baseField)

            let collection = { 'collection': collectionName }
            if (_.find(fields, collection) === undefined) {
              fields.push(collection)
            }
          } else {
            fields.push(this.createField(baseField))
          }
        }
        if (group.fields.length > 0) {
          this.schema.groups.push(this.createGroup(group, fields))
        }
      }

      this.addFieldCollectionToSchema()
    },
    addFieldCollectionToSchema () {
      for (let g = 0; g < this.schema.groups.length; g++) {
        let group = this.schema.groups[g]
        let groupFields = Object.values(group.fields)
        for (let f = 0; f < groupFields.length; f++) {
          let baseField = groupFields[f]

          if (baseField.collection !== undefined) {
            let baseCollectionFields = []
            let collectionFields = this.schemaCollection[baseField.collection]
            let fieldModel = this.model[baseField.collection]
            let collectionLength = this.getCollectionLength(fieldModel)

            this.$set(this.schema.groups[g].fields[f], 'type', 'collection')
            this.$set(this.schema.groups[g].fields[f], 'model', 'model')
            this.$set(this.schema.groups[g].fields[f], 'schema', { groups: [] })

            for (let c = 0; c < collectionLength; c++) {
              let fields = []

              for (let f = 0; f < collectionFields.length; f++) {
                let createdField = this.createField(collectionFields[f], c)
                createdField.collectionName = baseField.collection
                fields.push(createdField)

                if (c === 0) {
                  let collectionField = _.cloneDeep(createdField)
                  collectionField.model = collectionFields[f].field.model
                  baseCollectionFields.push(collectionField)
                }
              }

              this.schema.groups[g].fields[f]['schema']['groups'].push({ fields: fields })
            }

            this.$set(this.schema.groups[g].fields[f], 'baseCollectionFields', baseCollectionFields)
            this.$set(this.schema.groups[g], 'fieldCollection', true)
          }
        }
      }
    },
    createGroup (group, fields) {
      let result = {
        fields
      }
      if (group.label) {
        result.legend = group.label
      }
      return result
    },
    createField (fieldItem, collectionIndex = null) {
      let field = fieldItem.field

      let fieldParams = {
        type: field.type,
        label: field.label,
        inputName: field.model,
        disabled: this.isFieldDisabled(fieldItem),
        required: fieldItem.required ? fieldItem.required : false,
        relatedFields: []
      }

      let fieldModel = this.getPrepareFieldModel(field, collectionIndex)
      Object.assign(fieldParams, fieldModel)

      if (field.settings !== null) {
        Object.assign(fieldParams, this.setSettings(field))
      }

      return fieldParams
    },
    setSettings (field) {
      let settings = field.settings

      if (settings.value) {
        this.model[field.model] = settings.value
      }

      let fieldParams = {}
      fieldParams.readonly = settings.readonly ? settings.readonly : false
      fieldParams.relatedFields = settings.relatedFields ? settings.relatedFields : []
      Object.assign(fieldParams, this.setSpecificSettings(field))

      return fieldParams
    },
    setSpecificSettings (field) {
      let fieldParams = {}

      switch (field.type) {
        case 'input':
          fieldParams.inputType = field.inputType
          fieldParams.maxlength = field.settings.maxlength
          break

        case 'select':
          fieldParams.values = field.settings.values
          fieldParams.selectOptions = { hideNoneSelectedText: true }
          break

        case 'radios':
          fieldParams.values = field.settings.values
          break

        case 'autocomplete':
          fieldParams.source = '/'
          break
        case 'multiselect':
        case 'postCodeMultiselect':
        case 'personMultiselect':
          fieldParams = this.prepareMultiselectParams(field)
          break

        case 'dateTimePicker':
          fieldParams.dateTimePickerOptions = field.settings.dateTimePickerOptions
      }

      return fieldParams
    },
    isFieldDisabled (fieldItem) {
      if (_.has(fieldItem, 'field.settings.readonly')) {
        return fieldItem.field.settings.readonly
      }
      return fieldItem.readonly ? fieldItem.readonly : false
    },
    getCollectionLength (fieldModel) {
      let collectionLength = 1

      if (_.isArray(fieldModel) && fieldModel.length > 1) {
        collectionLength = fieldModel.length
      }

      return collectionLength
    },
    formatSourceModelName (source) {
      return source.split('').filter(char => char !== '[' && char !== ']' && char !== '.').join('')
    },
    prepareMultiselectParams (field) {
      let fieldParams = {}
      let fieldModel = this.model[field.model]

      if (field.settings.params) {
        fieldParams.params = field.settings.params
      }

      if (field.settings.source) {
        fieldParams.source = field.settings.source
      }

      if (field.settings.options) {
        fieldParams.options = field.settings.options
      }

      if (_.isArray(fieldModel) && !_.isEmpty(fieldModel)) {
        let collectionField = fieldModel[field.collectionIndex]

        let options = {
          id: collectionField.id,
          label: collectionField.forename + ' ' + collectionField.surname,
          entity: collectionField
        }

        fieldParams.options = [options]
        fieldParams.value = options
      }

      return fieldParams
    },
    getPrepareFieldModel (field, collectionIndex = null) {
      let fieldParams = {}
      let fieldModel = field.model

      if (collectionIndex !== null) {
        fieldParams.collectionIndex = collectionIndex
        fieldParams.model = fieldModel.replace(/\[i\]/g, '[' + collectionIndex + ']')
        fieldParams.modelPlaceholder = field.model
      } else {
        fieldParams.model = field.model
      }

      return fieldParams
    },
    modelUpdated (e, source) {
      let model = this.modelChanged
      if (typeof source === 'object') {
        model[this.formatSourceModelName(source.collectionName + 'Collection')] = true
      } else {
        model[this.formatSourceModelName(source)] = true
      }
      this.modelChanged = model
      this.validateRequirement()
    },
    submitField (newVal, schema, target) {
      if (!this.modelChanged[this.formatSourceModelName(schema.model)]) {
        return
      }

      if(schema.type === 'postCodeMultiselect' && newVal !== null && newVal.hasOwnProperty('city')) {
        newVal.borough = newVal.municipality
        Object.entries(newVal).filter(([key,value], index) => schema.relatedFields.includes(key)).forEach(([key,value], index) => {
          this.model[key] = value
        })
      }
      target.classList.add('saving-field')

      // wait for all watchers to finish
      this.$nextTick(() => {
        api.request(this.service, 'put', this.$isWithClients(this.$route.meta.acl.service) ? `/tasks/${this.$route.params.appraisalTaskId}` : `/tasks/${this.$route.params.id}/appraisal/${this.$route.params.appraisalTaskId}`, this.createFieldDataToSend(newVal, schema))
          .then(() => {
            this.modelChanged[this.formatSourceModelName(schema.model)] = false
            target.classList.remove('saving-field')
            target.classList.add('field-saved')
            setTimeout(() => target.classList.remove('field-saved'), 1500)
          })
          .catch(() => {
            this.modelChanged[this.formatSourceModelName(schema.model)] = false
            target.classList.remove('saving-field')
            target.classList.add('field-error')
            setTimeout(() => target.classList.remove('field-error'), 3600)
            this.$notify({
              type: 'error',
              text: 'Błąd zapisu danych'
            })
          })
      })
    },
    createFieldDataToSend (newVal, schema) {
      let dataToSend = {}
      dataToSend[schema.model] = newVal

      if (this.fieldSendDataMap[schema.model] !== undefined) {
        newVal = this.fieldSendDataMap[schema.model].method(newVal, ...this.fieldSendDataMap[schema.model].args)
      }
      dataToSend[schema.model] = newVal

      if (schema.hasOwnProperty('relatedFields')) {
        schema.relatedFields.forEach((relatedField) => {
          if (this.fieldSendDataMap[relatedField] !== undefined) {
            dataToSend[relatedField] = this.fieldSendDataMap[relatedField].method(this.model[relatedField], ...this.fieldSendDataMap[relatedField].args)
          }
        })
      }

      if (this.$isWithClients(this.service)) {
        dataToSend['appraisal'] = true
        dataToSend['client'] = this.$route.meta.client
      }
      return dataToSend
    },
    submitCollection (collectionNewVal, schema, target, shouldRemove) {
      if (!this.modelChanged[this.formatSourceModelName(schema.hasOwnProperty('model') ? schema.model : schema.collectionName + 'Collection')]) {
        return
      }

      if (!shouldRemove) {
        target.classList.add('saving-field')
      }

      // wait for all watchers to finish
      this.$nextTick(() => {
        api.request(this.service, 'put', !this.$isWithClients(this.service) ? `/tasks/${this.schemaName}/${this.$route.params.appraisalTaskId}?type=collection` : `/tasks/${this.$route.params.appraisalTaskId}`, this.createCollectionDataToSend(collectionNewVal, schema, shouldRemove))
          .then((response) => {
            this.modelChanged[this.formatSourceModelName(schema.hasOwnProperty('model') ? schema.model : schema.collectionName + 'Collection')] = false

            // update given collection item id
            if (response.data.processedEntityId) {
              this.model[schema.collectionName][schema.collectionIndex].id = response.data.processedEntityId
            }

            if (shouldRemove) {
              this.$notify({
                type: 'success',
                text: 'Usunięto wpis'
              })
            } else {
              target.classList.remove('saving-field')
              target.classList.add('field-saved')
              setTimeout(() => target.classList.remove('field-saved'), 1500)
            }
          })
          .catch(() => {
            this.modelChanged[this.formatSourceModelName(schema.hasOwnProperty('model') ? schema.model : schema.collectionName + 'Collection')] = false
            if (!shouldRemove) {
              target.classList.remove('saving-field')
              target.classList.add('field-error')
              setTimeout(() => target.classList.remove('field-error'), 3600)
            }

            this.$notify({
              type: 'error',
              text: this.$t('pages.taskDetails.form.errorSaveData')
            })
          })
      })
    },
    createCollectionDataToSend (collectionNewVal, schema, shouldRemove) {
      let dataToSend = {}
      dataToSend.collectionName = schema.collectionName

      if (this.$isWithClients(this.service)) {
        dataToSend.type = 'collection'
      }

      if (this.fieldSendDataMap[schema.collectionName] !== undefined) {
        collectionNewVal = this.fieldSendDataMap[schema.collectionName].method(collectionNewVal)
      }
      dataToSend[schema.collectionName] = collectionNewVal

      if (shouldRemove) {
        dataToSend.propertiesChanged = Object.keys(collectionNewVal).filter(prop => {
          const propsToBeOmitted = [
            'id',
            // 'mainTask',
            'appraisalTask',
            'personFullname'
          ]
          return !propsToBeOmitted.includes(prop)
        }).map(prop => schema.collectionName + '[i].' + prop)

        dataToSend.action = 'delete'
      } else {
        // convert to default collection property name to match form field model name
        dataToSend.propertiesChanged = [schema.model.replace(/\[\d+\]/g, '[i]')]
      }
      if (this.$isWithClients(this.service)) {
        dataToSend['appraisal'] = true
        dataToSend['client'] = this.$route.meta.client
      }

      return dataToSend
    },
    prepareContactPersonData (collectionNewVal) {
      return {
        id: this.getModelDataByPath(collectionNewVal, 'id'),
        appraisalTask: this.$route.params.appraisalTaskId,
        person: this.getModelDataByPath(collectionNewVal, 'person.id'),
        personFullname: this.concatPersonDataForHistory(collectionNewVal),
        victimType: this.getModelDataByPath(collectionNewVal, 'victimType'),
        contactPhone: this.getModelDataByPath(collectionNewVal, 'contactPhone')
      }
    },
    prepareOwnerData (collectionNewVal) {
      return {
        id: this.getModelDataByPath(collectionNewVal, 'id'),
        appraisalTask: this.$route.params.appraisalTaskId,
        person: this.getModelDataByPath(collectionNewVal, 'person.id'),
        personFullname: this.concatPersonDataForHistory(collectionNewVal),
        victimType: this.getModelDataByPath(collectionNewVal, 'victimType'),
        contactPhone: this.getModelDataByPath(collectionNewVal, 'contactPhone')
      }
    },
    concatPersonDataForHistory (collectionData) {
      const personId = this.getModelDataByPath(collectionData, 'person.id')
      if (personId === null) {
        return null
      }
      const isCompany = this.getModelDataByPath(collectionData, 'person.company')
      return (isCompany ? this.getModelDataByPath(collectionData, 'person.name') : this.getModelDataByPath(collectionData, 'person.forename') + ' ' + this.getModelDataByPath(collectionData, 'person.surname')) + ' [' + personId + ']'
    }
  }
}
</script>
